import { API_BASE } from './const';
import axios from 'axios';

import cameraService from './services/cameraService';


const StreamImageMain = {
    data() {
        return {
            camera: {},
            settings: [],
            image: {},
            viewUrl: undefined,
        }
    },

    computed: {
        now() {
            return Date.now();
        }
    },

    methods: {
        getCamera(id){
            var headers = {
                'Authorization': localStorage.token 
            }

            var url = API_BASE + '/camera/' + id ;
            return axios
                .get(url, {
                    headers: headers
                })
                .then(response => {
                    this.camera = response.data;
                    return response.data;
                })
                .catch(response => (this.message = response));
        },


        getCameraSettings(id) {
            var headers = {
                'Authorization': localStorage.token 
            }

            var url = API_BASE + '/camera/' + id + '/setting' ;
            axios
                .get(url, {
                    headers: headers
                })
                .then(response => {
                    this.settings = response.data;
                })
                .catch(response => (this.message = response));
            
        },


        getLastImageViewBySource(id){
            var headers = {
                'Authorization': localStorage.token 
            }

            var url = API_BASE + '/camera/' + id + '/last-image/download' ;
            axios
                .get(url, {
                    headers: headers
                })
                .then(response => {
                    console.log(response.data);
                    this.aa = response.data;
                })
                .catch(response => (this.message = response));
        },


        getLastImageBySource(){
            var headers = {
                'Authorization': localStorage.token 
            }

            if (!this.viewUrl){
                var url = API_BASE + '/camera/' + this.camera.id + '/last-image';
                axios
                    .get(url, {
                        headers: headers
                    })
                    .then(response => {
                        this.image = response.data;
                    })
                    .catch(response => (this.message = response));

                this.viewUrl = API_BASE + '/camera/' + this.camera.id + '/last-image/download?' + Date.now();    
            }
        },


        getImage(imageId) {
            var headers = {
                'Authorization': localStorage.token 
            }

            if (!this.viewUrl){
                var url = API_BASE + '/image/' + imageId;
                axios
                    .get(url, {
                        headers: headers
                    })
                    .then(response => {
                        this.image = response.data;
                        this.viewUrl = this.image.imagePath;
                    })
                    .catch(response => (this.message = response));
            }
        },

        previous() {
            var headers = {
                'Authorization': localStorage.token 
            }

            var url = API_BASE + '/image/' + this.image.id + '/previous';
            axios
                .get(url, {
                    headers: headers
                })
                .then(response => {
                    this.image = response.data;
                    this.viewUrl = API_BASE + '/image/'+this.image.id+'/download';

                    // let's also change url
                    var new_url = '/stream-image.html?cameraId=' + this.camera.id +  '&imageId=' + this.image.id;
                    window.history.pushState('data', 'smartsight', new_url);
                })
                .catch(response => (this.message = response));
        },


        next() {
            var headers = {
                'Authorization': localStorage.token 
            }

            var url = API_BASE + '/image/' + this.image.id + '/next';
                axios
                    .get(url, {
                        headers: headers
                    })
                    .then(response => {
                        this.image = response.data;
                        this.viewUrl = API_BASE + '/image/'+this.image.id+'/download';

                        // let's also change url
                        var new_url = '/stream-image.html?cameraId=' + this.camera.id +  '&imageId=' + this.image.id;
                        window.history.pushState('data', 'smartsight', new_url);
                    })
                    .catch(response => (this.message = response));
        },


        backToStream() {
            window.location.href = '/stream.html?id=' + this.camera.id;
            
        },


        screenshot(imageId) {
            var data = {}

            var headers = {
                'Authorization': localStorage.token 
            }

            var url = API_BASE + '/image/' + imageId + '/screenshot';
            return axios
                .put(url, data, {
                    headers: headers
                })
                .then(response => {
                    this.image = response.data;
                    this.viewUrl = this.image.imagePath;
                })
                .catch(response => (this.message = response));
        }
    },


    mounted() {
        if (localStorage.email == undefined || localStorage.token == undefined) {
            window.location.href = '/signin.html';
        }

        // URL should contain id of desirable camera stream
        let urlParams = new URLSearchParams(window.location.search);

        var cameraId = urlParams.get('cameraId');

        var imageId = urlParams.get('imageId');

        this.getCamera(cameraId).then(responseData => {
            this.getCameraSettings(cameraId);

            if (imageId) {
                this.getImage(imageId);
            } else {
                this.getLastImageBySource();    
            }
        });
    }    
}

if (window.location.pathname.includes("stream-image.html")) {
    Vue.createApp(StreamImageMain).mount('#stream-image-main');
}