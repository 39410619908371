import { API_BASE } from './const';
import axios from 'axios';

import cameraService from './services/cameraService';


const CameraMain = {
    data() {
        return {

            /* new camera */
            url: undefined,
            name: undefined,
            description: undefined,
            username: undefined,
            password: undefined,
            detection: ["person", "car"],
            notification: [], // no notification by default, allowed values ["email", "sms"]
            startDate: undefined,
            startTime: undefined,
            timeFrom: undefined,
            timeTo: undefined,

            addCamera: false,
            creatingCamera: false,

            /* existing cameras */
            cameras: [],

            limitation: {}
        }
    },

    methods: {
        createCamera(event) {
            this.creatingCamera = true;

            event.preventDefault();

            var data = {
                url: this.url,
                username: this.username,
                password: this.password,
                name: this.name,
                description: this.description,
                detection: this.detection,
                notification: this.notification,
                timeFrom: this.timeFrom,
                timeTo: this.timeTo
            }

            if (this.startDate && this.startTime) {
                data.startDateTime = this.startDate + 'T' + this.startTime + 'Z'
            } else if (this.startDate) {
                data.startDateTime = this.startDate + 'T00:00:00';
            }


            var headers = {
                'Authorization': localStorage.token 
            }

            var url = API_BASE + '/camera';
            axios
                .post(url, data, {
                    headers: headers
                })
                .then(response => {
                    this.message = response.data;
                    console.log(response.data);

                    this.getLimitations();

                    this.getCameras();

                    // reset fields
                    this.url = '';
                    this.username = '';
                    this.password = '';
                    this.name =  '';
                    this.description = '';
                    this.detection = ["person", "car"];
                    this.date = undefined;
                    this.time = undefined;

                    this.creatingCamera = false;
                    window.scrollTo(0, 0);
                })
                .catch(response => (this.message = response));

        },


        removeCamera(id) {
            cameraService.removeCamera(id).then(
                (response) => {
                    window.location.href = '/camera.html';
                }
            );
        },


        getCameras(){
            var headers = {
                'Authorization': localStorage.token 
            }

            var url = API_BASE + '/camera';
            axios
                .get(url, {
                    headers: headers
                })
                .then(response => {
                    this.cameras = response.data;
                    this.cameras[0].id;
                })
                .catch(response => (this.message = response));
    
        },


        pauseCamera(id){
            this.changeCameraState(id, 'PAUSE');
        },


        startCamera(id){
            this.changeCameraState(id, 'ANALYSIS');
        },


        changeCameraState(id, state){
            cameraService.changeCameraState(id, state).then(
                (response) => {
                    for (let i = 0; i < this.cameras.length; i++) {
                        if (this.cameras[i].id == response.id) {
                            this.cameras[i] = response;
                        }
                    }
                }
            );
        },


        screenshot(cameraId){
            return API_BASE + '/camera/'+cameraId+'/screenshot/download';
        },


        getLimitations() {

            var headers = {
                'Authorization': localStorage.token 
            }

            var url = API_BASE + '/account/limitation';
            axios
                .get(url, {
                    headers: headers
                })
                .then(response => {
                    this.limitation = response.data;
                })
                .catch(response => (this.message = response));
        },


        showAddCameraButton() {
            if (this.limitation.availableCameras == 0) {
                return false;
            }

            return !this.addCamera;
        },


        showCloseButton() {
            if (this.cameras.length == 0) {
                return false;
            }

            return this.addCamera; 
        },


        showAddCamera() {
          this.addCamera = true;
        },


        hideAddCamera() {
            this.addCamera = false;
        },


        notificationSupported() {
            return this.limitation.plan != 'FREE';
        },


        startDateTimeSupported() {
            return this.limitation.plan != 'FREE';
        },


        timeRangeSupported() {
            return this.limitation.plan == 'BUSINESS' || this.limitation.plan == 'ENTERPRISE';
        },


        cameraUsageLimitationText() {
            return '(' + this.cameras.length + '/' + (this.cameras.length + this.limitation.availableCameras) + ')' ;
        }
    },

    computed: {
         isNewCameraValid() {
            // url check
            var urlOk = false;
            if (this.url) {
                try {
                    url = new URL(this.url);
                    urlOk = true;
                } catch (_) {
                    urlOk = false;
                }
            }

            // name check
            var nameOk = false;
            if (this.name) {
                nameOk = true;
            }

            return urlOk && nameOk;
        }
    },


    mounted() {
        this.getLimitations();

        if (localStorage.email == undefined || localStorage.token == undefined) {
            window.location.href = '/signin.html';
        }

        this.getCameras();
    }

}

if (window.location.pathname.includes("camera.html")) {
    Vue.createApp(CameraMain).mount('#camera-main');
}