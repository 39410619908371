import { API_BASE } from './const';
import axios from 'axios';

const SignUpHandling = {
    data() {
        return {
            message: '',
            email: '',
            password: ''
        }
    },
    methods: {
        signUp(e) {
            e.preventDefault() // Prevent page from reloading.

            var data = {
                email: this.email,
                password: this.password
            }

            var url = API_BASE + '/signup';
            axios
                .post(url, data)
                .then(response => {

                    if (response.data.verify) {
                        window.location.href = '/verify.html';    
                    } else {
                        localStorage.email = response.data.email;
                        localStorage.token = response.data.token;

                        window.location.href = '/image.html';
                    }
                })
                .catch(response => {
                    if (response.response && response.response.status == 409) {
                        this.message = 'Email adress already registered.';
                    } else {
                        this.message = 'Unable to sign up. Please try later.';
                    }
                });
        }
    }
};


const LoginHandling = {
    data() {
        return {
            message: '',
            email: '',
            password: ''
        }
    },
    methods: {
        logIn(e) {
            e.preventDefault() // Prevent page from reloading.

            var data = {
                email: this.email,
                password: this.password
            }

            var url = API_BASE + '/signin';
            axios
                .post(url, data)
                .then(response => {
                    localStorage.email = response.data.email;
                    localStorage.token = response.data.token;

                    window.location.href = '/dashboard.html';

                })
                .catch(response => (this.message = response));

        }
    }
};

const SidenavHandling = {
    data() {
        return {
            isActiveDashboard: false,
            isActiveImage: false,
            isActiveCamera: false,
            isActivePlan: false,
            isActiveOrder: false
        }
    },

    mounted() {
        this.isActiveDashboard = (window.location.pathname === '/dashboard.html');
        this.isActiveImage = (window.location.pathname === '/image.html');
        this.isActiveCamera =   (window.location.pathname === '/camera.html'
                              || window.location.pathname === '/stream.html'
                              || window.location.pathname === '/stream-image.html');
        this.isActivePlan = (window.location.pathname === '/plan.html');
        this.isActiveOrder = (window.location.pathname === '/order.html');        
    }
};

if (window.location.pathname.includes("signup.html")) {
    Vue.createApp(SignUpHandling).mount('#signup');
}

if (window.location.pathname.includes("login.html")) {
    Vue.createApp(LoginHandling).mount('#login');
}

//TODO: duplicates array in account.js
var signedPages = ['/dashboard.html', '/image.html', '/camera.html', '/stream.html', '/plan.html', '/order.html', '/stream-image.html'];

if (signedPages.indexOf(window.location.pathname) >= 0) {
    Vue.createApp(SidenavHandling).mount('#sidenav');
}

