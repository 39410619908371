import { API_BASE } from './const';
import axios from 'axios';


const PlanMain = {
    data() {
        return {
            // expected values: 'CURRENT' or 'ORDER'    
            view: 'CURRENT',
            // expected values: 'PERSONAL' or 'BUSINESS' or 'DEVELOPER' or ENTERPRISE 
            orderType: 'PERSONAL',
            order: {},

            newOrder: {
                cameras: '',
                days: '',
                months: '',
                images: ''
            },

            pricing: {},

            payment: null,

            payments: [],

            limitation: {}
        }
    },

    methods: {
        createPayment(event, orderType) {
            event.preventDefault();

            var url = null;
            var data = null;
            var headers = {
                'Authorization': localStorage.token 
            }

            if (orderType == 'PERSONAL') {
                url = API_BASE + '/order/plan/personal';
                var data = {
                    cameras: this.newOrder.cameras,
                    days: this.newOrder.days    
                }
            } else if (orderType == 'BUSINESS') {
                url = API_BASE + '/order/plan/business';
                var data = {
                    cameras: this.newOrder.cameras,
                    months: this.newOrder.months
                }
            }

            axios
                .post(url, data, {
                    headers: headers
                })
                .then(response => {
                    this.order = response.data;
                    console.log(this.order);

                    this.getPaymentByOrder(this.order.id).then(responseData => {
                        this.payment = responseData;
                    });
                })
                .catch(response => (this.message = response));
        },


        getLimitations() {
            var headers = {
                'Authorization': localStorage.token 
            }

            var url = API_BASE + '/account/limitation';
            axios
                .get(url, {
                    headers: headers
                })
                .then(response => {
                    this.limitation = response.data;
                })
                .catch(response => (this.message = response));
        },


        getPaymentByOrder(orderId) {
            var headers = {
                'Authorization': localStorage.token 
            }

            var url = API_BASE + '/order/' + orderId + '/payment';

            return axios
                .get(url, {
                    headers: headers
                })
                .then(response => {
                    return response.data;
                })
                .catch(response => (this.message = response));
        },


        getOrders(){
            var headers = {
                'Authorization': localStorage.token 
            }

            var url = API_BASE + '/order';
            axios
                .get(url, {
                    headers: headers
                })
                .then(response => {
                    this.payments = response.data; 
                })
                .catch(response => (this.message = response));
    
        },


        getPayments(){
            var headers = {
                'Authorization': localStorage.token 
            }

            var url = API_BASE + '/payment';
            axios
                .get(url, {
                    headers: headers
                })
                .then(response => {
                    this.payments = response.data; 
                })
                .catch(response => (this.message = response));
    
        },


        getPricing(){
            var headers = {
                'Authorization': localStorage.token 
            }

            var url = API_BASE + '/pricing';
            axios
                .get(url, {
                    headers: headers
                })
                .then(response => {
                    this.pricing = response.data; 
                })
                .catch(response => (this.message = response));

        },


        changeView(view) {
            this.view = view;
        },


        changeOrderType(orderType) {
            this.orderType = orderType;
        },


        isNumberKey(evt) {
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode < 48 || charCode > 57))
                evt.preventDefault();

            return true;
        },
    },


    computed: {
        isOrderValid() {
            if (this.orderType == 'PERSONAL') {
                if (this.newOrder != null && this.newOrder.cameras > 0 && this.newOrder.days > 0) {
                    var price = this.pricing.personalPerDay * this.newOrder.cameras * this.newOrder.days;
                    this.pricing.value = (Math.round(price * 100) / 100).toFixed(2);
                    return true;   
                }
            } else if (this.orderType == 'BUSINESS') {
                if (this.newOrder != null && this.newOrder.cameras > 0 && this.newOrder.months > 0) {
                    var price = this.pricing.businessPerMonth * this.newOrder.cameras * this.newOrder.months;
                    this.pricing.value = (Math.round(price * 100) / 100).toFixed(2);
                    return true;   
                }
            } else if (this.orderType == 'DEVELOPER') {
                if (this.newOrder != null && this.newOrder.cameras > 0 && this.newOrder.images > 0) {
                    var price = this.pricing.developerPerImage * this.newOrder.images;
                    this.pricing.value = (Math.round(price * 100) / 100).toFixed(2);
                    return true;
                }
            }

            return false; 
        }
    },


    mounted() {

        if (localStorage.email == undefined || localStorage.token == undefined) {
            window.location.href = '/signin.html';
        }

        this.getLimitations();

        this.getPayments();

        this.getPricing();
    }

}

if (window.location.pathname.includes("plan.html")) {
    Vue.createApp(PlanMain).mount('#plan-main');
}