import { API_BASE } from './const';
import axios from 'axios';


const OrderMain = {
    data() {
        return {
            orders: [],

            payment: undefined,

            limitation: {}
        }
    },

    methods: {
        getLimitations() {
            var headers = {
                'Authorization': localStorage.token 
            }

            var url = API_BASE + '/account/limitation';
            axios
                .get(url, {
                    headers: headers
                })
                .then(response => {
                    console.log(response.data);
                    this.limitation = response.data;
                })
                .catch(response => (this.message = response));
        },


        getPaymentByOrder(orderId) {
            var headers = {
                'Authorization': localStorage.token 
            }

            var url = API_BASE + '/order/' + orderId + '/payment';

            axios
                .get(url, {
                    headers: headers
                })
                .then(response => {
                    this.payment = response.data;
                })
                .catch(response => (this.message = response));
        },


        cancelOrder(orderId) {
            var url = API_BASE + '/order/' + orderId;

            var headers = {
                'Authorization': localStorage.token 
            }

            axios
                .delete(url, {
                    headers: headers
                })
                .then(response => {
                    this.message = response.data;

                    this.getLimitations();

                    this.getOrders();
                })
                .catch(response => (this.message = response));
        },


        getOrders(){
            var headers = {
                'Authorization': localStorage.token 
            }

            var url = API_BASE + '/order';
            axios
                .get(url, {
                    headers: headers
                })
                .then(response => {
                    this.orders = response.data; 
                    console.log(response.data);
                })
                .catch(response => (this.message = response));
    
        },


        changeView(view) {
            this.view = view;
        },


        changeOrderType(orderType) {
            this.orderType = orderType;
        },
    },

    mounted() {

        if (localStorage.email == undefined || localStorage.token == undefined) {
            window.location.href = '/signin.html';
        }

        this.getLimitations();

        this.getOrders();
    }

}

if (window.location.pathname.includes("order.html")) {
    Vue.createApp(OrderMain).mount('#order-main');
}