// common account
const AccountHeader = {
    data() {
        return {
            email: '',
        }
    },

    methods: {
        logout() {
            localStorage.clear();
            window.location.href = '/index.html';
        }
    },

    mounted() {

        if (localStorage.email == undefined || localStorage.token == undefined) {
            window.location.href = '/signin.html';
        }

        this.email = localStorage.email;
    }

};

//TODO: duplicates array in user.js
var signedPages = ['/dashboard.html', '/image.html', '/camera.html', '/stream.html', '/plan.html', '/order.html', '/stream-image.html'];

if (signedPages.indexOf(window.location.pathname) >= 0) {
    Vue.createApp(AccountHeader).mount('#account-header');
}