import { API_BASE } from './const';
import axios from 'axios';

import Dropzone from 'dropzone';


const ImageMain = {
    data() {
        return {
            detection: ["person", "car"],
            url: API_BASE + '/image',

            limitation: {},

            dropzone: {},
            imageResponse: undefined,
            imagePath: '',
            
            uploading: false
        }
    },

    methods: {
        getLimitations() {

            var headers = {
                'Authorization': localStorage.token 
            }

            var url = API_BASE + '/account/limitation';
            axios
                .get(url, {
                    headers: headers
                })
                .then(response => {
                    console.log(response.data);
                    this.limitation = response.data;
                })
                .catch(response => (this.message = response));
        },


        getDropzoneUrl () {
            return this.url;
        },

        
        onAddedFile(file) {
            this.uploading = true;
            this.imagePath = '';
        },


        onSuccess(response) {
            var responseBody = JSON.parse(response.xhr.response);

            this.imageResponse = responseBody;
            this.imagePath = API_BASE + '/image/'+this.imageResponse.id+'/download';
            this.uploading = false;
        },


        onError(response) {
            console.log(response);
            console.log(response.xhr.response.code);

            var responseObj = JSON.parse(response.xhr.response);

            if ('IMAGE_ANALYSIS' === responseObj.code) {
                window.location.href = '/image.html';
            }
        }

    },

    watch: {
        detection(newValue) {
            if (newValue.length == 2 || newValue.length == 0) {
                this.url = API_BASE + '/image';
            } else {
                this.url = API_BASE + '/image?detection=' + newValue.join(',');
            }

            window.dropzoneUrl = this.url;
        }
    },

    mounted() {
        this.getLimitations();

        this.dropzone = createDropzone(
            this.getDropzoneUrl,
            this.onSuccess,
            this.onAddedFile,
            this.onError);
    }
};


function createDropzone(uploadUrl, onSuccess, onAddedFile, onError) {
    // Finding chart HTML element
    const fileDropzone = document.getElementById('fileDropzone');
    if (! fileDropzone) {
        console.warn("Unable to find HTML chart element 'fileDropzone' ");
        return;
    }

    const elementOptions = fileDropzone.dataset.dropzone ? JSON.parse(fileDropzone.dataset.dropzone) : {};

    let currentFile = undefined;

    const defaultOptions = {
        method: 'post',
        headers: { "Authorization": localStorage.token },
        previewsContainer: fileDropzone.querySelector('.dz-preview'),
        previewTemplate: '<div style="display:none"></div>',
        init: function () {
          this.on('addedfile', onAddedFile);
          this.on('success', onSuccess);
          this.on('error', onError);
        },
    };

    const options = {
        ...elementOptions,
        ...defaultOptions,
        url: uploadUrl,
        dictDefaultMessage: 'Drop image for an analyze here'
    };

    // Init dropzone
    var myDropzone = new Dropzone(fileDropzone, options);

    myDropzone.on("queuecomplete", function(file, res) {
      if (myDropzone.files[0].status == Dropzone.SUCCESS ) {
          console.info('Image uploaded and analyzed.');
      } else {
          console.error('Unable to upload the image.');
      }
  });

  return myDropzone;
}



if (window.location.pathname.includes("image.html")) {
    Vue.createApp(ImageMain).mount('#image-main');
}