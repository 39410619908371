import { API_BASE } from './const';
import axios from 'axios';


const VerifyMain = {
    data() {
        return {
            verified: false,
            headerMessage: 'Verification in progress ...',
            bodyMessage: 'Please visit your email and confirm by provided link.',
        }
    },

    methods: {
        verify(token) {
            var data = {}

            var headers = {
                'Authorization': token 
            }

            var url = API_BASE + '/verify';
            axios
                .put(url, data, {
                    headers: headers
                })
                .then(response => {
                    console.log(response);
                    this.verified = true;
                    this.headerMessage='Verification successful!';
                    this.bodyMessage='Thank you for verification, that you are not a <i>machine</i>.';
                })
                .catch(response => (this.message = response));
        },

        getPayments(){
            var headers = {
                'Authorization': localStorage.token 
            }

            var url = API_BASE + '/payment';
            axios
                .get(url, {
                    headers: headers
                })
                .then(response => {
                    this.payments = response.data; 
                    console.log(response.data);
                })
                .catch(response => (this.message = response));
        }
    },


    mounted() {
        let urlParams = new URLSearchParams(window.location.search);
        let token = urlParams.get('token');

        if (token) {
            this.verify(token);
        }
    }
}

if (window.location.pathname.includes("verify.html")) {
    Vue.createApp(VerifyMain).mount('#verify-main');
}