//
// dropzone.js
// Theme module
//
import { API_BASE } from './const';

import Dropzone from 'dropzone';

window.dropzoneUrl = API_BASE + '/image/analyze';

const getDropzoneUrl = () => {
  return window.dropzoneUrl;
}


Dropzone.autoDiscover = false;
Dropzone.thumbnailWidth = null;
Dropzone.thumbnailHeight = null;

const toggles = document.querySelectorAll('[data-dropzone]');
/*
toggles.forEach((toggle) => {
  let currentFile = undefined;

  const elementOptions = toggle.dataset.dropzone ? JSON.parse(toggle.dataset.dropzone) : {};

  const defaultOptions = {
    method: 'post',
    headers: { "Authorization": localStorage.token },
    previewsContainer: toggle.querySelector('.dz-preview'),
    previewTemplate: toggle.querySelector('.dz-preview').innerHTML,
    init: function () {
      this.on('addedfile', function (file) {
        const maxFiles = elementOptions.maxFiles;

        if (maxFiles == 1 && currentFile) {
          this.removeFile(currentFile);
        }

        currentFile = file;
      });
      this.on('success', function (response) {
        console.log(response);
        console.log(response.xhr.response);

        var responseBody = JSON.parse(response.xhr.response);
        console.log(responseBody.resultPath);
        //dz-preview-img dz-preview-cover
        toggle.querySelector('.dz-result-img').innerHTML = '<img class="" style="width: 100%;" alt="result" src="'+ responseBody.imagePath +'">';

        toggle.querySelector('.dz-response').innerHTML = '<p>'+ JSON.stringify(responseBody); +'</p>';

      });
    },
  };

  const options = {
    ...elementOptions,
    ...defaultOptions,
    url: getDropzoneUrl
  };

  // Clear preview
  toggle.querySelector('.dz-preview').innerHTML = '';

  // Init dropzone
  new Dropzone(toggle, options);
});

// Make available globally
window.Dropzone = Dropzone;
*/