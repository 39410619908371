import { API_BASE } from '../const';
import axios from 'axios';

export default {

    PAUSE: Symbol('PAUSE'),
    ANALYSIS: Symbol('ANALYSIS'),

    changeCameraState(id, state){
        var stateStr;
        if (typeof state === 'symbol') {
            stateStr = state.description;
        } else {
            stateStr = state;
        }

        var data = {
            state: stateStr  
        }

        var headers = {
            'Authorization': localStorage.token 
        }

        var url = API_BASE + '/camera/' + id + '/state';
        return axios
            .put(url, data, {
                headers: headers
            })
            .then(response => {

                window.dispatchEvent(new CustomEvent('camera-state-changed', {
                    detail: response.data
                }));

                return response.data;
            })
            .catch(response => (this.message = response));
    },
    
    
    removeCamera(id) {
        var url = API_BASE + '/camera/' + id;

        var headers = {
            'Authorization': localStorage.token 
        }

        return axios
            .delete(url, {
                headers: headers
            })
            .then(response => {
                return response.data;
            })
            .catch(response => (this.message = response));
    }
};