import { API_BASE } from './const';
import axios from 'axios';

import cameraService from './services/cameraService';
import eventService from './services/eventService';

const StreamMain = {
    data() {
        return {
            camera: '',
            settings: '',
            image: '',
            viewUrl: undefined,
            events: {},

            limitation: {}
        }
    },

    computed: {
        now() {
            return Date.now();
        }
    },

    methods: {
        getCamera(id){
            var headers = {
                'Authorization': localStorage.token 
            }

            var url = API_BASE + '/camera/' + id ;
            axios
                .get(url, {
                    headers: headers
                })
                .then(response => {
                    this.camera = response.data;
                })
                .catch(response => (this.message = response));
        },


        getCameraSettings(id) {
            var headers = {
                'Authorization': localStorage.token 
            }

            var url = API_BASE + '/camera/' + id + '/setting' ;
            axios
                .get(url, {
                    headers: headers
                })
                .then(response => {
                    this.settings = response.data;
                })
                .catch(response => (this.message = response));
            
        },


        getLastImageViewBySource(id){
            var headers = {
                'Authorization': localStorage.token 
            }

            var url = API_BASE + '/camera/' + id + '/last-image/download' ;
            return axios
                .get(url, {
                    headers: headers
                })
                .then(response => {
                    return response.data;
                })
                .catch(response => (this.message = response));
        },


        getLastImageBySource(){
            var headers = {
                'Authorization': localStorage.token 
            }

            if ('ANALYSIS' === this.camera.state || !this.viewUrl){
                var url = API_BASE + '/camera/' + this.camera.id + '/last-image';
                axios
                    .get(url, {
                        headers: headers
                    })
                    .then(response => {
                        this.image = response.data;

                        this.getEventsByImageId(this.image.id);
                    })
                    .catch(response => (this.message = response));

                this.viewUrl = API_BASE + '/camera/' + this.camera.id + '/last-image/download?' + Date.now();    
            }
        },


        pauseCamera(id){

            cameraService.changeCameraState(id, cameraService.PAUSE).then(
                (response) => {
                    this.camera = response;
                }
            );
        },


        startCamera(id){
            cameraService.changeCameraState(id, cameraService.ANALYSIS).then(
                (response) => {
                    this.camera = response;
                }
            );
        },


        removeCamera(id) {
            cameraService.removeCamera(id).then(
                (response) => {
                    window.location.href = '/camera.html';
                }
            );
        },


        getLimitations() {

            var headers = {
                'Authorization': localStorage.token 
            }

            var url = API_BASE + '/account/limitation';
            axios
                .get(url, {
                    headers: headers
                })
                .then(response => {
                    console.log(response.data);
                    this.limitation = response.data;
                })
                .catch(response => (this.message = response));
        },


        getEventsByImageId(imageId){
            eventService.getEventsByImageId(imageId).then(
                (response) => {
                    this.events = response;
                }
            );
        },
    },

    mounted() {
        if (localStorage.email == undefined || localStorage.token == undefined) {
            window.location.href = '/signin.html';
        }

        this.getLimitations();

        // URL should contain id of desirable camera stream
        var pair = window.location.search.split('=');
        var cameraId = pair[1];

        this.getCamera(cameraId);
        this.getCameraSettings(cameraId);

        //this.interval = setInterval(this.getLastImageBySource(pair[1]), 2000)
        this.interval = setInterval(this.getLastImageBySource, 5000)
    },
    beforeDestroy() {
        this.interval = undefined;
    }    

}

if (window.location.pathname.includes("stream.html")) {
    Vue.createApp(StreamMain).mount('#stream-main');
}