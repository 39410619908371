import { API_BASE } from './const';
import axios from 'axios';

import Dropzone from 'dropzone';


const DashboardMain = {
    data() {
        return {
            limitation: {}
        }
    },

    methods: {
        getLimitations() {

            var headers = {
                'Authorization': localStorage.token 
            }

            var url = API_BASE + '/account/limitation';
            axios
                .get(url, {
                    headers: headers
                })
                .then(response => {
                    console.log(response.data);
                    this.limitation = response.data;
                })
                .catch(response => (this.message = response));
        }
    },

    mounted() {
        this.getLimitations();
    }
};


if (window.location.pathname.includes("dashboard.html")) {
    Vue.createApp(DashboardMain).mount('#dashboard-main');
}