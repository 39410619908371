import { API_BASE } from '../const';
import axios from 'axios';

export default {

    getEventsByImageId(imageid){

        var headers = {
            'Authorization': localStorage.token 
        }

        var url = API_BASE + '/event/image/' + imageid;
        return axios
                .get(url, {
                    headers: headers
                })
                .then(response => {
                    return  response.data;
                })
                .catch(response => (this.message = response));
    }
};